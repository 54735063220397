@import '../../style/variables';
@import '../../style/_commons.scss';

#footer {

  background-color: $pale-grey;
  color: $space-gray;

  #logo {
    font-size: 3em;
  }

  a.footer-logo {
    text-decoration: none;
    color: $space-gray;
  }

}

.footer-content {
  padding: 1.4em 2em 1em;

  & > div {
    border-right: solid 1px $input-border;

    &:last-child {
      border-right: inherit;
    }
  }
}

.footer-list {
  text-align: right;

  h3 {
    padding-top: 8px;
    padding-bottom: 10px;
    margin: 0;
  }

  ul li {
    list-style: none;
    line-height: 40px;
  }

  ul {
    margin-top: inherit !important;
  }

  li {
    text-align: left;
  }

  a, .list-text {
    text-decoration: none;
    color: $soft-grey;

  }

  a:hover {
    text-decoration: underline;
    color: $space-gray;
  }
}


#about-company {
  .title {
    font-weight: bold;
    text-decoration: underline;
    text-underline-position: under;
  }
  .direction {
    line-height: 1.4em;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 990px) {

  #footer {

    #logo {
      font-size: 2em;
    }

  }

}

@media only screen and (max-width: 991px) {
  #footer {
    margin-bottom: 150px;
    position: relative;

    #logo {
      font-size: 2em;

      span {
        display: inline;
      }
    }

    .footer-content div {
      border-right: 0;
    }

    .logo-display {
      width: 90%;
      position: absolute;
      bottom: -95px;
    }

    .footer-list h3 {
      text-align: left;
    }
  }
}
