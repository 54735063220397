$royal: #250f8a;
$marigold: #ffca00;
$white: #ffffff;
$pale-grey: #f7f7f7;
$soft-grey: #B5B5B5;
$space-gray: #727272;
$input-border: #ccc;
$black: #000000;
$lime: #32cd32;
$pink: #f87668;
$default-font-family: 'Helvetica';
$dark-blue: #2346A2;
$low-white: #ffffff8a;

$headerHeight: 60px;