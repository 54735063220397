@import '../../style/_variables.scss';
@import '../../style/_commons.scss';


.banner- {

  &content {
    @extend %light-blue-gradient;
    overflow: hidden;
    padding: 4em 6em;

    &.row {
      margin: 0;
    }
  }

  &text {
    color: $white;
    text-decoration: underline $marigold;
    text-underline-position: under;
  }

  &button {
    a {
      margin: 0;

    }
  }
}



@media only screen and (max-width: 870px) {

  .banner-content {
    padding: 2em;
    display: flex;
    justify-content: center;
    text-align: center;
  }

}