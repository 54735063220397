@import '../../../style/variables';

.intro-picture {
  min-height: 200px;
}

.button-advance {
  position: absolute;
  bottom: 20px;
  background: white;
  width: 60px;
  height: 60px;
  right: calc(50% - 30px);
  border-radius: 30px;
  box-shadow: 5px 7px 11px 0 #9090907a;
  z-index: 10;

  .arrow {
    box-sizing: border-box;
    position: absolute;
    left: 10px;
    top: 13px;
    color: $royal;
  }
}

.animated-logo {
  height: 17em;
}

.animated-text {
  font-weight: 200;
  font-size: 24px;
}

@keyframes blink {
  50% { border-color: #fff; }
}

.wrap {
  animation: blink .5s step-end infinite alternate;
}

.wrap {
  border-right: 0.08em solid transparent;
}

@media only screen and (max-width: 1100px) {

  .button-advance {
    bottom: 60px;
  }

}

@media only screen and (min-width: 765px) and (max-width: 1100px) {

  .button-advance {
    bottom: 50px;
  }

  .animated-text {
    font-size: 18px;
  }

}

@media only screen and (min-width: 400px) and (max-width: 765px) {

  .intro-button-advance {
    bottom: -200px;
  }

  .animated-text {
    font-size: 18px;
  }
}

@media only screen and (max-width: 400px) {

  .intro-picture {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .intro-button-advance {
    bottom: -320px;
  }

  .animated-text {
    font-size: 16px;
  }

}
