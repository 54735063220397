@import '../../style/variables';
@import '../../style/commons';


#big-header {

  color: $white;

  .info-container {
    position: relative;
    bottom: 4em;
  }

  h1 {
    font-size: 2.4em;
  }

  .image {
    height: 35em;

    & > div {
      height: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .tablet {
    & > div {
      background-position: top;
      margin-top: 2em;
    }
  }

  .hidden-list {
    display: none;
  }

  .text-list {
    padding-left: 0;

    li {
      margin-bottom: 1em;

      &:last-child {
        margin-bottom: inherit;
      }
    }
  }

}

@media only screen and (max-width: 767px) {
  #big-header {
    .image {
      display: none;
    }

    .header-list {
      &.info-container {
        bottom: 2em;
      }

      h1 {
        font-size: 1.6em;
      }
    }

  }
}

@media only screen and (max-width: 669px) {

  #big-header {

    .info-container {
      h1 {
        margin-bottom: 0;
      }
    }

    .header-list {
      &.info-container {
        li {
          font-size: 0.9em;
        }
      }
    }
  }
}