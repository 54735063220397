@import '../../style/_variables.scss';
@import '../../style/_commons.scss';

.button, .contact {
  &:hover {
    cursor: pointer;
  }

  @extend %button-hover-animation;
}

.button {
  padding: 13px 34px;
  font-size: 15px;
  text-decoration: none;
  background-color: $marigold;
  border-radius: 10rem;
  color: $royal;
  text-transform: uppercase;

  :visited {
    color: $royal;
  }
}

.contact {
  padding: 13px 34px;
  font-size: 15px;
  text-decoration: none;
  color: $white;
  border-radius: 10rem;
  text-transform: uppercase;
  margin: 15px;
  line-height: normal;
}

.blue {
  background-color: $royal;
}

.red {
  background-color: $pink;
}

.yellow {
  background-color: $marigold;
}

.green {
  background-color: #7CB46F;
}