@import '../../../style/_variables.scss';

.client-grid {

  p {
    color: $space-gray;
  }

  .info-container {
    padding-left: 1.6em;
    padding-right: 2em;
    text-align: center;
  }

  .image-container {

    &-smartphone {
      height: 40em;
    }

    &-tablet {
      height: 18em;
    }

    .image {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      height: 100%;
    }
  }
}

@media only screen and (max-width: 767px) {
  .client-grid {
    .info-container {
      padding-bottom: 1em;
      margin-bottom: 1em;
    }

    .info-container, .info-container-tablet {
      border-bottom: solid 1px $pale-grey;
      margin: 2em 0;
    }

    .image-container- {
      &smartphone {
        height: 28em;
      }

      &tablet {
        height: 14em;
      }
    }
  }
}