@import "./_variables.scss";

%light-blue-gradient {
  background: rgba(36, 72, 163, 1);
  background: -moz-linear-gradient(left, rgba(36, 72, 163, 1) 0%, rgba(36, 72, 163, 1) 54%, rgba(47, 221, 233, 1) 100%);
  background: -webkit-linear-gradient(left, rgba(36, 72, 163, 1) 0%, rgba(36, 72, 163, 1) 54%, rgba(47, 221, 233, 1) 100%);
  background: -o-linear-gradient(left, rgba(36, 72, 163, 1) 0%, rgba(36, 72, 163, 1) 54%, rgba(47, 221, 233, 1) 100%);
  background: -ms-linear-gradient(left, rgba(36, 72, 163, 1) 0%, rgba(36, 72, 163, 1) 54%, rgba(47, 221, 233, 1) 100%);
  background: linear-gradient(to right, rgba(36, 72, 163, 1) 0%, rgba(36, 72, 163, 1) 54%, rgba(47, 221, 233, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2448a3', endColorstr='#2fdde9', GradientType=1);
}

%dark-blue-gradient {
  background: rgba(35, 71, 162, 1);
  background: -moz-linear-gradient(left, rgba(35, 71, 162, 1) 0%, rgba(32, 133, 191, 1) 100%);
  background: -webkit-linear-gradient(left, rgba(35, 71, 162, 1) 0%, rgba(32, 133, 191, 1) 100%);
  background: -o-linear-gradient(left, rgba(35, 71, 162, 1) 0%, rgba(32, 133, 191, 1) 100%);
  background: -ms-linear-gradient(left, rgba(35, 71, 162, 1) 0%, rgba(32, 133, 191, 1) 100%);
  background: linear-gradient(to right, rgba(35, 71, 162, 1) 0%, rgba(32, 133, 191, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2347a2', endColorstr='#2085bf', GradientType=1);
}

%shadow-removed {
  -webkit-box-shadow: inherit;
  -moz-box-shadow: inherit;
  box-shadow: inherit;
}

%reset-button {
  background-color: inherit;
  color: inherit;
  padding: inherit;
  border: inherit;
  border-radius: inherit;
}

%reset-hover-focus-active {
  &:hover, &:active, &:focus {
    outline: none;
    border: none;
  }
}

%reset-list {
  ul {
    margin: inherit;
    list-style: none;
  }
}

%button-hover-animation {
  transition: all 200ms ease;
}

%shadow-sweet {
  -webkit-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.16);
}

%shadow-header {
  -webkit-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
}

%shadow-card {
  -webkit-box-shadow: 2px 3px 8px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 2px 3px 8px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 2px 3px 8px 0px rgba(0, 0, 0, 0.25);
}

%text-decoration-titles {
  text-decoration: underline;
  text-decoration-color: #ffca00;
  text-underline-position: under;
}