@import '../style/_variables.scss';
@import '../style/_commons.scss';

.microservices-container {

  margin: 6em 8em;

  section {
    margin-bottom: 2em;
  }

}

#categories {
  @extend %reset-list;
  margin-bottom: 2em;

  &-list {
    margin-bottom: 4em;

    li {
      overflow: hidden;
      border-left: solid 1px $soft-grey;
    }
  }

  .filterButton, .itemCategory {
    @extend %reset-hover-focus-active;

    &:hover {
      cursor: pointer;
    }
  }


  .filterButton,
  .filterButton:hover,
  .filterButton:active,
  .filterButton:focus,
  .filter-active {
    border: solid 2px $pink;
  }

  .filterButton {
    background-color: $pink;
    display: flex;

    @extend %button-hover-animation;
  }

  .filterButton:hover, .filter-active {
    background-color: $white;
    color: $pink;
  }

  .filter-icon {
    margin-right: 10px;
    height: auto;
    width: 1em;
  }

  .itemCategory {
    @extend %reset-button;
    padding: 0.8em 0;
    color: $space-gray;
    text-align: left;
    border-left: 0;

    span {
      padding: 0.2em 0.4em;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &:hover {
      color: $royal;

      span {
        background-color: rgba($space-gray, 0.15);
      }
    }
  }

  .itemCategory:active,
  .itemCategory:focus,
  .selectedItem {
    color: $royal;

    span {
      border-bottom: solid 2px $royal;
    }
  }

  .allItem {
    font-weight: bold;
  }

}

#feature-cards-scroll {
  position: relative;
  top: -100px;
}

@media only screen and (max-width: 991px) {

  .microservices-container {
    margin: 6em 4em;
  }

}

@media only screen and (max-width: 800px) {

  .microservices-container {
    margin: 4em 2em;
  }

}

@media only screen and (max-width: 767px) {

  .microservices-container {

    section {
      margin-bottom: 3em;
    }
  }

  #categories-list {
    margin: 2em 0;

    .container {
      margin: 0;
      padding: 0;
    }

    .row {
      padding: 0;
    }
  }
}

@media only screen and (max-width: 699px) {

  .microservices-container {
    margin: 2em 1em;
  }

  #categories-list {
    margin: 2em -15px;

    .container {
      padding: inherit;
    }
  }

  #categories {

    .itemCategory {

      span {
        padding: 0.2em 0;
      }

      &:hover {
        color: $royal;

        span {
          background-color: transparent;
        }
      }
    }
  }
}