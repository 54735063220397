@import '../../../style/variables';

#clients {
  //color: #fff;
  //
  //a {
  //  color: $white;
  //}

  .clients-card {
    background: #f7f7f7;
    margin: 0 10px 30px 10px;
    border-radius: 5px;
    padding: 20px;
    max-width: 900px;
    color: #000000;
    text-decoration: none;
    font-weight: lighter;
    line-height: 23px;
    font-size: 15px;

    &:hover {
      color: #000000;
      background-color: rgba(47, 221, 233, 0.2);
    }

    h2 {
      font-weight: lighter;
      font-size: 35px;
      line-height: 42px;
      padding-bottom: 6px;
    }

    h4 {
      font-weight: lighter;
      font-size: 20px;
    }

  }

  h1 {
    color: $black;
    font-size: 3em;
    padding-bottom: 0.5em;
    text-decoration: underline;
    text-decoration-color: $marigold;
    text-underline-position: under;
  }

  .background-client {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    transform: rotateY(180deg);
    width: 100%;
    height: 0;
    padding-top: 100%; /* (img-height / img-width * container-width) ---> example: (853 / 1280 * 100) */
  }

  .background-client-reverse {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    width: 100%;
    height: 0;
    padding-top: 100%;
  }

  @media only screen and (max-width: 765px) {
    .background-client {
      margin-bottom: 40px;
    }
  }
}

