@media only screen and (min-width: 400px) and (max-width: 765px) {
  .homePage {
    height: calc(100% + 240px);
  }
}

@media only screen and (max-width: 400px) {
  .homePage {
    height: calc(100% + 350px);
  }
}