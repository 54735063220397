@import '../../../style/variables';

#explore-features {

  h1 {
    font-size: 3em;
    padding-top: 1.5em;
    text-decoration: underline;
    text-decoration-color: $marigold;
    text-underline-position: under;
  }
}