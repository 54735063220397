@import '../style/_variables.scss';

.signup {
  height: 100%;
  min-height: 100%;

  & > div {
    position: relative;
    bottom: 4em;
  }
}

button {
  padding: 13px 34px;
  font-size: 15px;
  text-decoration: none;
  background-color: $royal;
  border-radius: 10rem;
  color: $white;
  text-transform: uppercase;

  :visited {
    color: $white;
  }

  img {
    max-height: 30px;
  }
}

#signup-form {
  input {
    border: solid 1px rgba(#ccc, 0.9);
    margin-bottom: 24px;
    height: 50px;
    font-size: 16px;
    font-weight: lighter;
    padding: 12px;
    width: 100%;
  }

  .phone-code-content {
    border: solid 1px rgba(#ccc, 0.9);
    border-radius: 0;
    background: #fff;
    -webkit-appearance: none;
    padding: 15px;
    font-size: 16px;
    margin-bottom: 24px;
    font-weight: lighter;
    width: 100%;
  }
}

.form-loading button {
  opacity: 0.8;
}

#get-started {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $white;
  box-shadow: 4px 5px 11px 1px #08090a3d;

  .get-started_footer {
    a {
      color: $royal;
      text-decoration: none;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 100%;
  }

  .get-started_header {
    align-self: flex-end;

    a {
      font-size: 22px;
    }
  }

  .get-started_form {
    display: flex;
    flex-direction: column;
    align-items: center;
    //min-width: 60%;
    margin-bottom: 60px;

    h2 {
      margin: 0;
      color: $royal;
      font-size: 28px;
      font-weight: normal;
      text-transform: uppercase;
    }

    p {
      font-size: 22px;
      font-weight: lighter;
    }
  }

  .get-started_footer {
    font-size: 12px;
  }

  .registration-complete {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-grow: 1;
    min-height: 400px;
    text-align: center;

    p {
      line-height: 32px;
    }

    .registration-complete__title {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      h2 {
        color: $royal;
        font-size: 30px;
        font-weight: 300;
      }

      i {
        color: $lime;
        font-size: 45px;
        margin-bottom: 27px;
      }
    }
  }

  .error-message {
    color: red;
    padding: 5px 25px;
    font-weight: lighter;
    font-size: 15px;
    line-height: 22px;
    background-color: rgba(red, 0.1);
    border-radius: 4px;

    p {
      font-size: 14px;
      font-weight: lighter;
      margin: 0;
      line-height: 19px;
    }
  }
  .message-box {
    display: flex;
    flex-direction: row;

    p {
      padding-left: 10px;
      display: flex;
      align-items: center;
    }

  }
}

@media only screen and (max-width: 669px) {

  #contact {
    margin-top: 2em;
  }

  .signup {
    & > div {
      position: inherit;
    }
  }

  .get-started_footer {
    padding-bottom: 6em;
  }

}

@media only screen and (max-width: 375px) {

  #contact {
    margin-top: 5em;
    margin-bottom: 3em;
  }

  .get-started_footer {
    padding-bottom: 6em;
  }

}


@media only screen and (max-width: 360px) {

  #contact {
    margin-top: 8em;
    margin-bottom: 5em;
  }

  .get-started_footer {
    padding-bottom: 8em;
  }

}

@media only screen and (min-width: 1400px) {

  #contact {
    margin-top: 5em;
    margin-bottom: 8em;
  }

}