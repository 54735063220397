@import '../../style/_variables.scss';
@import '../../style/_commons.scss';


.microservice-card, .icon-container, .icon {
  display: flex;
  align-items: center;
  text-align: center;
}

.microservice-card, .information-content {
  padding: 0 2em 0;
}

.microservice-card {

  @extend %shadow-card;
  background-color: $white;
  min-height: 420px;
  flex-direction: column;

  .icon-container {
    flex: 6;
    padding-top: 1em;
  }

  .icon {
    @extend %dark-blue-gradient;
    height: 6em;
    width: 6em;
    border-radius: 50px;
    justify-content: center;

    .microservice-icon {
      height: 4em;
      width: 4em;
      color: $white;
    }
  }

  .title {
    flex: 1;
    width: 100%;
    text-align: center;

    h1 {
      color: $royal;
      font-size: 2em;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .description {
    flex: 6;

    p {
      color: $space-gray;
      text-align: center;
    }
  }

  .button-container {
    flex: 4;
  }

  #more-information {
    position: absolute;
    height: 100%;
    width: 100%;
  }

  .information- {

    &show {
      opacity: 1;
    }

    &hidden {
      opacity: 0;
    }

    &more {
      height: 100%;
      background-color: rgba($marigold, 0.95);
      margin: 0 15px;

      .information-content {
        display: flex;
        flex-direction: column;
        height: 100%;
        text-align: left;
        &:hover {
          cursor: pointer;
        }
      }

      .title {
        flex: 1;
        text-align: left;
        padding-top: 2em;
        color: $white;
        display: flex;
        align-items: center;
      }

      .microservice-icon {
        height: 1.4em;
        width: 1.4em;
      }

      .text {
        flex: 4;
        color: rgba($black, 0.65);
      }

    }
  }

}



