/* ---- logo ---- */

#logo {
  text-decoration: none;
  text-transform: uppercase;
  font-size: 4em;
  font-family: 'Montserrat', sans-serif;

  span {
    display: inline;
  }

}

.logo-text-mesh {
  font-weight: bolder;
  display: inline-block;
  padding-right: 0.6em;
}

.mesh-decor {
  border-bottom: 2px solid;
}

.logo-text-platform {
  font-size: 42%;
  font-weight: normal;
  letter-spacing: 10px;
  padding-top: 15px;
}

@media only screen and (max-width: 767px) {

  #logo {

    span {
      display: flex;
      flex-wrap: wrap;
    }

    .logo-text-platform {
      letter-spacing: 6px;
    }
  }
}