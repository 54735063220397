@import '../../../../style/_variables.scss';
@import '../../../../style/_commons.scss';

#desktop-menu {

  .signUp {
    background-color: white;
    padding: 5px 12px;
    border-radius: 50px;
    color: $royal;

    &:hover {
      background-color: $pink;
    }
  }

  .menu-icon-container {
    padding: 7px 8px;
    border: solid 1px $low-white;
    border-radius: 50px;

    &:hover {
      border-color: $white;
      cursor: pointer;

      .menu-icon {
        color: $white;
      }
    }
  }

  .menu-icon, .contact-icon {
    color: $low-white;

    svg {
      width: 1.2em;
      height: 100%;
    }
  }

  .contact-icon {
    display: none;
  }

}

.desktop-menu-content {
  margin: 0;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
  list-style: none;

  li {
    margin-left: 2em;
  }
}


#modal-menu {
  max-width: 30em;
  position: fixed;
  background-color: rgba($pale-grey, 0.94);
  border-radius: 0 0 4px 4px;
  top: $headerHeight;
  right: 0;
  padding: 2em 1em;
  @extend %shadow-header;

  .row {
    padding: 2em 0;
    margin: 0 0.2em;
    border-bottom: solid 1px rgba($soft-grey, 0.4);

    &:first-child {
      padding-top: inherit;
    }

    &:last-child {
      padding-bottom: inherit;
      border-bottom: inherit;
    }

  }

  .title {
    margin-bottom: 1em;
    padding: 0;

    h2 {
      @extend %text-decoration-titles;
      margin: 0;
    }
  }

  .option {
    margin: 0.5em 0;
    text-align: center;

    & > a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &:hover {
      cursor: pointer;

      label {
        color: $royal;
      }
    }
  }

  .image-container {
    width: 3em;
    height: 3em;
    border-radius: 50px;
    overflow: hidden;
    margin-bottom: 1em;
    @extend %dark-blue-gradient;
  }

  .image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  label {
    text-transform: initial;
    color: $space-gray;

    &:hover {
      cursor: pointer;
    }
  }


}

.modal- {
  &show {
    display: block;
  }

  &hidden {
    display: none;
  }
}


@media only screen and (max-width: 669px) {
  .desktop-menu-content {
    & > li {
      margin-left: 0.6em;
    }
  }

  #modal-menu {
    max-width: inherit;
    width: 100%;
  }


  #desktop-menu {

    .signUp {
      background-color: inherit;
      border: solid 1px $low-white;
      padding: 9px 8px;

    }

    .contact {
      &-text {
        display: none;
      }

      &-icon {
        display: inherit;
      }
    }
  }

}