@import '../../../style/variables';

.go-to-top-container {
  background: white;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  box-shadow: 5px 7px 11px 0 #9090907a;
  box-sizing: border-box;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 10;

  .arrow {
    box-sizing: border-box;
    position: absolute;
    left: 10px;
    top: 9px;
    color: $royal;
  }
}

.displayBlock {
  display: block;
}
.displayNone {
  display: none;
}