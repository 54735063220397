@import '../style/_variables.scss';

#terms-of-service {

  background-color: $white;

  p, ul {
    color: $space-gray;
  }

  .text-block {
    padding-top: 30px;
  }
}