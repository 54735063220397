@import '../../../style/_variables.scss';
@import '../../../style/_commons.scss';


#features-used {

  .features- {

    &content {
      padding: 4em 6em;
      border-top: rgba($soft-grey, 0.4) solid 1px;
    }

    &text {
      color: $space-gray;
      text-decoration: underline $marigold;
      text-underline-position: under;
      font-size: 12px;
      padding-bottom: 4em;
      line-height: 40px;
    }

    &button {
      .mt-3 {
        margin-top: 0 !important;
        margin-right: 0;
        float: right;
      }

      a {
        margin: 0 auto;
        white-space: nowrap;
      }
    }

    &area {
      padding: 0;

      & > .container-fluid {
        padding: 0;
      }
    }

    &service {
      text-align: center;
      color: $space-gray;
      margin-bottom: 2em;
    }

    &icon {
      font-size: 2em;
    }
  }
}

@media only screen and (max-width: 1200px) {

  #features-used {

    .row {
      justify-content: center;
    }

    .features- {

      &text {
        text-align: center;
      }

      &button {
        margin: 3em;

        .mt-3 {
          float: inherit;
        }
      }
    }
  }
}