@import './_variables.scss';
@import './_commons.scss';

html, body {
  scroll-behavior: smooth;
  font-family: $default-font-family;
  margin: 0;
  min-height: 100%;
  height: 100%;
}

* {
  //border: 1px solid red;
}

.fullScreenContainer {
  min-height: 100%;
  height: 100%;
}

.body-bg {
  background: url('../static/background-top.svg') center top no-repeat;
  background-size: cover;
}

.top-background {
  background: url("../static/background-top.svg") bottom center no-repeat;
  background-size: cover;
  // min-height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.text-center {
  text-align: center;
}

.no-text-decoration {
  text-decoration: none;
}

.button-hover-animation {
  border: none;
  padding: 10px;
  font-size: 18px;
  border-radius: 5px;
  position: relative;
  box-sizing: border-box;
  @extend %button-hover-animation;
}

//header-links
.header-link {
  list-style: none;
  text-transform: uppercase;
  font-size: 14px;

  &.highlight {
    a {
      color: $royal;
      opacity: 1;
    }
  }
}

.header-link a {
  color: $white;
  opacity: 0.5;
  text-decoration: none;

  &:active {
    opacity: 1 !important;
  }
}

.header-link-background {
  background-color: $white;
  border-radius: 10rem;
  font-family: 'Monaco', serif;
}

//#intro section
#intro {
  color: $white;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  .intro-image {
    width: 80%;
    padding-top: 30px;
  }

  h1 {
    font-size: 45px;
  }

  .font-24 {
    font-size: 24px;
  }

  .intro-container {
    margin: 0 5px;
  }
}

.align-content-middle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-solution {
  font-size: 12px;
}

//#explore-features
#explore-features {
  color: $black;
}

.features-content {
  .container, .featured-image {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

//#landing - news section
#news {
  color: $black;
  background: $white url("../static/sausage-right.svg") no-repeat center left;
  background-size: 15%;
}

.splash-content {
  background: url("../static/splash.svg") no-repeat left -10px top -20px;
  background-size: 50%;
}

.container-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: lighter;
  line-height: 23px;
  font-size: 15px;

  h2 {
    font-weight: lighter;
    font-size: 35px;
    line-height: 42px;
  }
}

#resilent {
  background-color: $pale-grey;
}

#collaboration {
  background: $pale-grey url("../static/sausage-left.svg") no-repeat center right;
  background-size: 15%;
  background-color: $white;
}

#customizable {
  background: $white url("../static/sausage-left.svg") no-repeat center right;
  background-size: 15%;
}

#allinone {
  background-color: $white;
}

.featured-image {
  width: 80%;
  max-width: 400px;
  background-image: url("../static/Shapes-4.svg");
  background-size: contain;
}

.featured-image-s {
  width: 60%;
  max-width: 300px;
  background-image: url("../static/Shapes-4.svg");
  background-size: contain;
}

.feature-header {
  color: $black;
}

.feature-text {
  color: $black;
  opacity: 0.5;
}

#testimony {
  background-image: url('../static/shape-cards.svg'), linear-gradient(90deg, rgba(37, 15, 138, 1) 0%, rgba(2, 236, 241, 1) 100%);
  background-size: cover;
  min-height: 450px;
  color: #fff;

  .testimony-card {
    background: #fff3;
    margin: 0 10px 30px 10px;
    border-radius: 5px;
    padding: 20px;
    max-width: 300px;
    color: #FFFFFF;
    text-decoration: none;


    &:hover {
      color: #b5b5b5;
      background-color: #1F497D;
    }

    .title {
      font-size: 14pt;
    }

    .subtitle {
      font-size: 10pt;
    }
  }

  .avatar {
    border-radius: 50%;
    max-width: 70px;
    max-height: 70px;
  }

  .avatar:hover {
    border-radius: 60%;
    max-width: 72px;
    max-height: 72px;
  }
}

#plans {
  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $white;

    h1 {
      margin: 0;
      font-size: 45px;
      font-weight: lighter;
    }

    h4 {
      font-weight: lighter;
      font-size: 20px;
    }
  }

  .plans-content {
    align-items: center;
  }

  a {
    background-color: $royal;
    padding: 13px 39px;
    border-radius: 12px;
    color: $white;
    text-decoration: none;

    &:hover {
      background-color: rgb(62, 40, 138);
    }
  }
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  background-color: $white;
  min-height: 570px;
  height: 570px;
  z-index: 0;
  border: 1px solid rgb(223, 223, 223);

  &:nth-child(1) {
    border-right: none;
  }

  &:nth-child(3) {
    border-left: none;
  }

  .badge {
    position: absolute;
    top: 3px;
    left: 3px;
    font-size: 12px;
    background-color: $royal;
    color: $white;
    padding: 5px 10px;
    border-radius: 4px;
  }

  span {
    color: rgb(74, 74, 74);
    line-height: 22px;
  }
}

.card-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.center-card {
  min-height: 720px;
  height: 720px;
  z-index: 2;
  box-shadow: 0 0 9px 1px #bdbdbd6b;
}

#card-choose {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .card {
    position: relative;
    box-shadow: 0 5px 11px 1px #08090a3d;
    background-color: $white;
    max-width: 70%;
    min-width: 70%;
    max-height: 80%;
    min-height: 80%;
  }

  .card-title {
    flex-grow: 1;
  }

  span {
    flex-grow: 2;
    text-align: center;
  }
}

.go-to-top-container {
  background-color: $white;
  border-radius: 40px;
  position: fixed;
  bottom: 10px;
  right: 6px;
  box-shadow: 5px 7px 11px 0 #9090907a;
  width: 60px;
  height: 60px;

  a {
    width: 30px;
    height: 30px;
    box-sizing: border-box;
    position: fixed;
    bottom: 20px;
    right: 20px;
    transform: rotate(-45deg);

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      border-width: .8vmin .8vmin 0 0;
      border-style: solid;
      border-color: $royal;
      transition: .2s ease;
      display: block;
      transform-origin: 100% 0;
    }

    &:after {
      content: '';
      float: left;
      position: relative;
      top: -100%;
      width: 100%;
      height: 100%;
      border: 0 solid rgb(37, 15, 138);
      border-right-width: .8vmin;
      transform-origin: 100% 0;
      transition: .2s ease;
    }
  }
}

.displayBlock {
  display: block;
}

.displayNone {
  display: none;
}

