@import '../style/_variables.scss';
@import '../style/_commons.scss';

.client-grid-container {
  padding-right: 2em;
  padding-left: 2em;
  margin-bottom: 6em;
  margin-top: 2em;
}

.text-container {
  text-align: center;

  p {
    color: $space-gray;
  }
}