@import '../../style/_variables.scss';
@import '../../style/_commons.scss';


#header {

  height: $headerHeight;
  padding: 0 3em;

  & > div {
    height: 100%;
  }

  &.header-background {
    background: $white url("../../static/background-top.svg") center top no-repeat;
    background-size: cover;
    width: 100%;
    @extend %shadow-header;
  }

  &-logo {
    height: 100%;
    display: flex;
    align-items: center;
  }

  #logo {
    color: $white;
    font-size: 24px;

    span {
      display: inline;
    }
  }

  a {
    color: $low-white;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 15px;

    &:hover {
      color: $white;
    }
  }

}
.show-logo {
  display: block;
}

.hidden-logo {
  display: none !important;
}


@media only screen and (max-width: 768px) {

  #header {

    .home-link {
      display: none;
    }

  }
}


@media only screen and (max-width: 669px) {

  #header {

    padding: 0 1em;

    #logo {
      //margin-top: 18px;
    }

  }
}