@import '../../../style/_variables.scss';

#platform-features {

    background-image: url('../../../static/shape-cards.svg'), linear-gradient(90deg, rgba(37, 15, 138, 1) 0%, rgba(2, 236, 241, 1) 100%);
    background-size: cover;
    color: #fff;

    #features-carousel {
        margin: 0 33px;
    }

    .slick-slide {
        display: flex;
        justify-content: center;
    }

    .slick-arrow.slick-prev::before, .slick-arrow.slick-next::before {
        display: none;
    }

    .slider-arrows {
        color: $white;
        font-size: 35px;
    }

    .text-center {
        h1 {
            font-size: 3em;
            padding-top: 1.5em;
            text-decoration: underline;
            text-decoration-color: $marigold;
            text-underline-position: under;
        }
     }

    .carousel-box {
        width: 21.5em !important;
    }

    @media only screen and (max-width: 400px) {
        #features-carousel {
            margin: 0 5px;

            h1 {
                font-size: 24px;
            }
        }

        .carousel-box {
            width: 15em !important;
        }

        .slider-arrows {
            color: $white;
            font-size: 25px;
        }
    }

    @media only screen and (min-width: 401px) and (max-width: 530px) {
        #features-carousel {
            margin: 0 15px;

            h1 {
                font-size: 28px;
            }
        }

        .carousel-box {
            width: 17em !important;
        }

        .slider-arrows {
            color: $white;
            font-size: 30px;
        }
    }

    @media only screen and (min-width: 531px) and (max-width: 1500px) {
        #features-carousel {
            margin: 0 30px;
        }

        .carousel-box {
            width: 21.5em !important;
        }

        .slider-arrows {
            color: $white;
            font-size: 30px;
        }
    }

    @media only screen and (min-width: 1501px) {
        .carousel-box {
            width: 25em !important;
        }
    }

    @media only screen and (min-width: 1650px) {
        #features-carousel {
            margin: 0 10%;
        }
    }
}